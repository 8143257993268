const config = {
  // https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
  Auth: {
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true
  },
  // https://docs.amplify.aws/lib/restapi/getting-started/q/platform/js#manual-setup-import-existing-rest-api
  API: {
    endpoints: [
      {
        name: process.env.VUE_APP_API_NAME,
        endpoint: process.env.VUE_APP_API_ENDPOINT,
        region: process.env.VUE_APP_API_REGION
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_BUCKET_NAME,
      region: process.env.VUE_APP_AWS_REGION
    }
  }
};

export default config;
