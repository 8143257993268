import { createStore } from "vuex";
import { API, Auth } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";
import router from "../router";

export default createStore({
  strict: true, // process.env.NODE_ENV !== 'production',
  state: {
    isSidebarMinimized: false,
    userName: "Kevin C",
    user: null,
    isAuthenticated: false,
    authenticationStatus: null,
    registerMemberCount: null,
    uniqueUserCreateFormCount: null,
    totalFormCount: null,
    paidUserCount: null,
    weeklyPaidUserCount: null,
    formFilledCount: null,
    respondedCount: null,
    totalUserCount: null,
    expiredMemberCount: null,
  },
  mutations: {
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized;
    },
    changeUserName(state, newUserName) {
      state.userName = newUserName;
    },
    setRegisterMemberCount(state, payload) {
      state.registerMemberCount = payload;
    },
    setUniqueUserCreateFormCount(state, payload) {
      state.uniqueUserCreateFormCount = payload;
    },
    setTotalFormCount(state, payload) {
      state.totalFormCount = payload;
    },
    setPaidUserCount(state, payload) {
      state.paidUserCount = payload;
    },
    setWeeklyPaidUserCount(state, payload) {
      state.weeklyPaidUserCount = payload;
    },
    setFormFilledCount(state, payload) {
      state.formFilledCount = payload;
    },
    setRespondedCount(state, payload) {
      state.respondedCount = payload;
    },
    setTotalUserCount(state, payload) {
      state.totalUserCount = payload;
    },
    setExpiredMemberCount(state, payload) {
      state.expiredMemberCount = payload;
    },
    setUserAuthenticated(state, user) {
      state.user = user;
      state.isAuthenticated = true;
    },
    clearAuthenticationStatus(state) {
      state.authenticationStatus = null;
    },
    setAuthenticationError(state, err) {
      console.debug("auth error: {}", err);
      state.authenticationStatus = {
        state: "failed",
        message: err.message,
        code: err.code,
        variant: "danger",
      };
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    authenticationStatus: (state) => state.authenticationStatus,
    registerMemberCount: (state) => state.registerMemberCount,
    uniqueUserCreateFormCount: (state) => state.uniqueUserCreateFormCount,
    totalFormCount: (state) => state.totalFormCount,
    paidUserCount: (state) => state.paidUserCount,
    formFilledCount: (state) => state.formFilledCount,
    respondedCount: (state) => state.respondedCount,
    totalUserCount: (state) => state.totalUserCount,
    expiredMemberCount: (state) => state.expiredMemberCount,
    weeklyPaidUserCount: state => state.weeklyPaidUserCount
  },
  actions: {
    clearAuthenticationStatus: (state) => {
      state.commit("clearAuthenticationStatus");
    },
    signIn: async (state, params) => {
      console.debug("signIn for {}", params.username);
      state.commit("clearAuthenticationStatus");
      try {
        const user = await Auth.signIn(params.username, params.password);

        const result = await API.post(API_NAME, `/admin/authenticate`);

        if (result.authenticated) {
          state.commit("setUserAuthenticated", user);

          user.deviceKey =
            user.signInUserSession.accessToken.payload.device_key;

          if (params.remember) {
            user.setDeviceStatusRemembered({
              onSuccess: function(result) {
                console.log("call result: " + result);
              },

              onFailure: function(err) {
                console.log(err);
              },
            });
            localStorage.setItem("rememberDevice", true);
          } else {
            user.setDeviceStatusNotRemembered({
              onSuccess: function(result) {
                console.log("call result: " + result);
              },

              onFailure: function(err) {
                console.log(err);
              },
            });
            localStorage.setItem("rememberDevice", false);
          }
        }
      } catch (err) {
        state.commit("setAuthenticationError", err);
      }
    },
    signOut: async state => {
      try {
        router.push({ name: "login" });
        await Auth.signOut();
        localStorage.clear();
      } catch (err) {
        console.error("error during sign out: {}", err);
        state.commit("setAuthenticationError", err);
      }
    },
    getRegisterMemberCount: async (state, { startDate, endDate }) => {
      try {
        const registerMember = await API.get(
          API_NAME,
          `/admin/register-member-count?startDate=${startDate}&endDate=${endDate}`
        );
        state.commit("setRegisterMemberCount", registerMember);
      } catch (err) {
        console.log(err);
      }
    },
    getUniqueUserCreateFormCount: async (state, { startDate, endDate }) => {
      try {
        const uniqueUser = await API.get(
          API_NAME,
          `/admin/unique-user-count?startDate=${startDate}&endDate=${endDate}`
        );
        state.commit("setUniqueUserCreateFormCount", uniqueUser);
      } catch (err) {
        console.log(err);
      }
    },
    getTotalFormCount: async (state, { startDate, endDate }) => {
      try {
        const totalForm = await API.get(
          API_NAME,
          `/admin/total-form-count?startDate=${startDate}&endDate=${endDate}`
        );
        state.commit("setTotalFormCount", totalForm);
      } catch (err) {
        console.log(err);
      }
    },
    getPaidUserCount: async state => {
      try {
        const paidUser = await API.get(API_NAME, `/admin/paid-user-count`);
        state.commit("setPaidUserCount", paidUser);
      } catch (err) {
        console.log(err);
      }
    },
    getWeeklyPaidUserCount: async (state, { startDate, endDate }) => {
      try {
        const paidUserWeekly = await API.get(
          API_NAME,
          `/admin/paid-user-count?startDate=${startDate}&endDate=${endDate}`
        );
        state.commit("setWeeklyPaidUserCount", paidUserWeekly);
      } catch (err) {
        console.log(err);
      }
    },
    getFormFilledCount: async (state, { startDate, endDate }) => {
      try {
        const formFilled = await API.get(
          API_NAME,
          `/admin/form-filled-count?startDate=${startDate}&endDate=${endDate}`
        );
        state.commit("setFormFilledCount", formFilled);
      } catch (err) {
        console.log(err);
      }
    },
    getRespondedCount: async (state, { startDate, endDate }) => {
      try {
        const responded = await API.get(
          API_NAME,
          `/admin/responded-count?startDate=${startDate}&endDate=${endDate}`
        );
        state.commit("setRespondedCount", responded);
      } catch (err) {
        console.log(err);
      }
    },
    getTotalUserCount: async state => {
      try {
        const totalUser = await API.get(API_NAME, `/admin/total-user-count`);
        state.commit("setTotalUserCount", totalUser);
      } catch (err) {
        console.log(err);
      }
    },
    getExpiredMemberCount: async (state) => {
      try {
        const totalUser = await API.get(
          API_NAME,
          `/admin/expired-member-count`
        );
        state.commit("setExpiredMemberCount", totalUser);
      } catch (err) {
        console.log(err);
      }
    }
  }
});
