export default {
  root: {
    name: "/",
    displayName: "navigationRoutes.home"
  },
  routes: [
    {
      name: "dashboard",
      displayName: "menu.dashboard",
      meta: {
        icon: "vuestic-iconset-dashboard"
      }
    },
    {
      name: "members",
      displayName: "menu.members",
      meta: {
        icon: "vuestic-iconset-user"
      },
      disabled: true
    }
    // {
    //   name: "transaction",
    //   displayName: "menu.transactionHistory",
    //   meta: {
    //     icon: "vuestic-iconset-maps"
    //   },
    //   disabled: true
    // }
    // {
    //   name: "workspaces",
    //   displayName: "menu.workspaces",
    //   meta: {
    //     icon: "vuestic-iconset-forms"
    //   },
    //   disabled: true,
    //   children: [
    //     {
    //       name: "workspace1",
    //       displayName: "menu.workspace1"
    //     }
    //     // {
    //     //   name: "charts",
    //     //   displayName: "menu.charts"
    //     // },
    //     // {
    //     //   name: "progress-bars",
    //     //   displayName: "menu.progressBars"
    //     // }
    //   ]
    // },
    // {
    //   name: "data",
    //   displayName: "menu.myDataSeries",
    //   meta: {
    //     icon: "vuestic-iconset-statistics"
    //   },
    //   disabled: true,
    //   children: [
    //     // {
    //     //   name: "form-elements",
    //     //   displayName: "menu.formElements"
    //     // },
    //     // {
    //     //   name: "medium-editor",
    //     //   displayName: "menu.mediumEditor"
    //     // }
    //     {
    //       name: "uncategorized-data",
    //       displayName: "menu.uncategorized"
    //     }
    //   ]
    // },
    // {
    //   name: "collections",
    //   displayName: "menu.myCollections",
    //   meta: {
    //     icon: "vuestic-iconset-tables"
    //   },
    //   children: [
    //     // {
    //     //   name: "markup",
    //     //   displayName: "menu.markupTables"
    //     // },
    //     {
    //       name: "uncategorized",
    //       displayName: "menu.uncategorized"
    //     }
    //     // {
    //     //   name: 'data',
    //     //   displayName: 'menu.dataTables',
    //     // },
    //   ]
    // },
    // {
    //   name: "sale",
    //   displayName: "menu.onSaleItems",
    //   meta: {
    //     icon: "vuestic-iconset-ui-elements"
    //   },
    //   disabled: true
    //   // children: [
    //   //   {
    //   //     name: "buttons",
    //   //     displayName: "menu.buttons"
    //   //   },
    //   //   {
    //   //     name: "cards",
    //   //     displayName: "menu.cards"
    //   //   },
    //   //   {
    //   //     name: "chat",
    //   //     displayName: "menu.chat"
    //   //   },
    //   //   {
    //   //     name: "chips",
    //   //     displayName: "menu.chips"
    //   //   },
    //   //   {
    //   //     name: "collapses",
    //   //     displayName: "menu.collapses"
    //   //   },
    //   //   {
    //   //     name: "colors",
    //   //     displayName: "menu.colors"
    //   //   },
    //   //   // {
    //   //   //   name: 'color-pickers',
    //   //   //   displayName: 'menu.colorPickers',
    //   //   // },
    //   //   {
    //   //     name: "file-upload",
    //   //     displayName: "menu.fileUpload"
    //   //   },
    //   //   {
    //   //     name: "grid",
    //   //     displayName: "menu.grid"
    //   //   },
    //   //   {
    //   //     name: "icon-sets",
    //   //     displayName: "menu.icons",
    //   //     children: [
    //   //       {
    //   //         displayName: "concrete",
    //   //         name: "icon-set"
    //   //       }
    //   //     ]
    //   //   },
    //   //   {
    //   //     name: "lists",
    //   //     displayName: "menu.lists"
    //   //   },
    //   //   {
    //   //     name: "modals",
    //   //     displayName: "menu.modals"
    //   //   },
    //   //   {
    //   //     name: "notifications",
    //   //     displayName: "menu.notifications"
    //   //   },
    //   //   {
    //   //     name: "popovers",
    //   //     displayName: "menu.popovers"
    //   //   },
    //   //   {
    //   //     name: "rating",
    //   //     displayName: "menu.rating"
    //   //   },
    //   //   {
    //   //     name: "sliders",
    //   //     displayName: "menu.sliders"
    //   //   },
    //   //   {
    //   //     name: "spacing",
    //   //     displayName: "menu.spacing"
    //   //   },
    //   //   {
    //   //     name: "spinners",
    //   //     displayName: "menu.spinners"
    //   //   },
    //   //   {
    //   //     name: "tabs",
    //   //     displayName: "menu.tabs"
    //   //   },
    //   //   {
    //   //     name: "timelines",
    //   //     displayName: "menu.timelines"
    //   //   },
    //   //   {
    //   //     name: "tree-view",
    //   //     displayName: "menu.treeView"
    //   //   },
    //   //   {
    //   //     name: "typography",
    //   //     displayName: "menu.typography"
    //   //   }
    //   // ]
    // },
    // {
    //   name: "pages",
    //   displayName: "menu.pages",
    //   meta: {
    //     icon: "vuestic-iconset-files"
    //   },
    //   disabled: true,
    //   children: [
    //     {
    //       name: "login",
    //       displayName: "menu.login-singup"
    //     },
    //     {
    //       name: "404-pages",
    //       displayName: "menu.404-pages"
    //     },
    //     {
    //       name: "faq",
    //       displayName: "menu.faq"
    //     }
    //   ]
    // }
  ]
};
